import Button from "antd/es/button"
import Form from "antd/es/form"
import TextArea from "antd/es/input/TextArea"
import Skeleton from "antd/es/skeleton"
import { collection, limit, orderBy, query } from "firebase/firestore"
import { useEffect, useState } from "react"
import { useCollectionDataOnce } from "react-firebase-hooks/firestore"
import { useParams } from "react-router"

import Header from "../../components/Header"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import useErrorPopup from "../../hooks/useErrorPopup"
import TranscriptFeed from "../../live/TranscriptFeed"
import { getTranscriptSearchResults } from "../../live/api"
import {
  LIVE_ASSISTED_CALLS_COLLECTION,
  LIVE_ASSISTED_CALLS_TRANSCRIPT_SEGMENTS_SUBCOLLECTION,
} from "../../live/db"
import type { LiveTranscriptSegment } from "../../live/types"
import { uniqueByKeyWithPriority } from "../../utils"

const _QUERIES_RIPPLING = [
  {
    fieldName: "Current_Systems__c", // Make this up for now
    fieldDisplay: "Current Systems",
    query:
      "What current systems did the customer mention that they have implemented?",
  },
  {
    fieldName: "Competitors__c", // Make this up for now
    fieldDisplay: "Competitors Being Evaluated",
    query:
      "What competitors to Rippling did the customer mention that they are also evaluating?",
  },
  {
    fieldName: "What_Have_They_Seen__c", // Make this up for now
    fieldDisplay: "What have they seen",
    query: "What risks are there to the deal?",
  },
  {
    fieldName: "Products_of_Interest__c", // Make this up for now
    fieldDisplay: "Product(s) of Interest/Proposed Agenda",
    query: "What products did the customer mention they are interested in?",
  },
  {
    fieldName: "Deal_Risks__c", // Make this up for now
    fieldDisplay: "Deal Risks",
    query: "What risks are there to the deal?",
  },
  {
    fieldName: "Sensitivity__c", // Make this up for now
    fieldDisplay: "Is there sensitivity to demoing pro/unlimited features?",
    query: "", // Skip
  },
  {
    fieldName: "Pain_Point__c", // Make this up for now
    fieldDisplay: "Current Pain Point/Specific Use Cases?",
    query:
      "What current pain points do the customers have? What are the specific use cases?",
  },
  {
    fieldName: "Stakeholders__c", // Make this up for now
    fieldDisplay: "Deal Stakeholders:",
    query: "", // Skip
  },
  {
    fieldName: "Additional_Info__c", // Make this up for now
    fieldDisplay: "Any additional information?",
    query:
      "What additional information about the deal would be valuable to know?",
  },
]

const queriesToUse = _QUERIES_RIPPLING

const fieldsToUse = queriesToUse.map((query) => query.fieldName)
const initialValues = Object.fromEntries(
  fieldsToUse.map((field) => [field, ""]),
)

const CRMUpdate: React.FC = () => {
  const { handleError } = useErrorPopup()
  const { callOid = "" } = useParams<{ callOid: string }>()
  const [submitting] = useState<boolean>(false) // TODO
  const [loadingCRMFields, setLoadingCRMFields] = useState<boolean>(false)
  const [form] = Form.useForm()

  const [dbTranscriptSegments, dbTranscriptSegmentsLoading, , , ,] =
    useCollectionDataOnce(
      query(
        collection(
          db,
          LIVE_ASSISTED_CALLS_COLLECTION,
          callOid,
          LIVE_ASSISTED_CALLS_TRANSCRIPT_SEGMENTS_SUBCOLLECTION,
        ).withConverter(makeConverter<LiveTranscriptSegment>()),
        orderBy("created_at", "asc"),
        limit(5000),
      ),
    )

  const transcriptSegments = uniqueByKeyWithPriority(
    dbTranscriptSegments ?? [],
    (a) => a.oid,
    (a) => a.created_at.toMillis(),
  )

  useEffect(() => {
    const fetchData = async () => {
      setLoadingCRMFields(true)
      await Promise.all(
        queriesToUse.map(async (query) => {
          if (!query.query) {
            return
          }
          try {
            const response = await getTranscriptSearchResults(
              callOid,
              query.query,
            )
            if (response.results) {
              form.setFieldsValue({
                [query.fieldName]: response.results
                  .map((result) => result.answer)
                  .join("\n"),
              })
            }
          } catch (error) {
            handleError({ error, prefix: "Could not suggest CRM update" })
          }
        }),
      )
      setLoadingCRMFields(false)
    }

    void fetchData()
  }, [callOid, form, handleError])

  return (
    <>
      <Header
        title="Your Calls"
        breadcrumbs={[
          { title: "Coaching Assistant", href: "/calls" },
          { title: callOid, href: `/calls/${callOid}` },
        ]}
      />
      <div className="flex w-full min-w-[600px] grow items-stretch overflow-y-hidden p-2 sm:p-4">
        <div className="w-full overflow-y-scroll">
          <Skeleton loading={dbTranscriptSegmentsLoading} avatar active>
            <TranscriptFeed segments={transcriptSegments} />
          </Skeleton>
        </div>
        <div className="flex w-full grow flex-col items-center gap-2 overflow-y-scroll rounded-b-lg border border-t-0 p-3">
          <Form
            layout="vertical"
            form={form}
            initialValues={initialValues}
            className="w-full"
          >
            {queriesToUse.map((query) => (
              <Skeleton key={query.fieldName} loading={loadingCRMFields} active>
                <Form.Item
                  name={query.fieldName}
                  label={query.fieldDisplay}
                  required
                >
                  <TextArea
                    autoSize
                    disabled={submitting}
                    title={query.fieldDisplay}
                    placeholder=""
                  />
                </Form.Item>
              </Skeleton>
            ))}
            <Button
              type="primary"
              className="w-26"
              style={{ float: "right" }}
              disabled={submitting}
              htmlType="submit"
            >
              {submitting ? "Submitting..." : "Submit"}
            </Button>
          </Form>
        </div>
      </div>
    </>
  )
}

export default CRMUpdate
