import Button from "antd/es/button"
import Form from "antd/es/form"
import Input from "antd/es/input"
import Popconfirm from "antd/es/popconfirm"
import Skeleton from "antd/es/skeleton"
import { doc } from "firebase/firestore"
import { useCallback, useEffect, useState } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useNavigate } from "react-router-dom"

import MarkdownEditor from "../components/MarkdownEditor"
import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import useErrorPopup from "../hooks/useErrorPopup"
import { GROUPS_COLLECTION } from "../types/common"
import {
  createLiveNotesTemplate,
  deleteLiveNotesTemplate,
  updateLiveNotesTemplate,
} from "./liveNotesTemplateApi"
import type { LiveNotesTemplate } from "./types"
import { LIVE_NOTES_TEMPLATE_SUBCOLLECTION } from "./types"

interface Props {
  liveNotesTemplateOid: string
}

const LiveNotesTemplateEditor: React.FC<Props> = ({ liveNotesTemplateOid }) => {
  const [templateName, setTemplateName] = useState<string>()
  const [templateContent, setTemplateContent] = useState<string>("")
  const [saving, setSaving] = useState<boolean>(false)
  const [deleting, setDeleting] = useState<boolean>(false)
  const { handleSuccess, handleError } = useErrorPopup()
  const navigate = useNavigate()

  const { authUser, activeGroupOid } = useActiveUserAuthorizationFromContext()

  const [liveNotesTemplate, liveNotesTemplateLoading, liveNotesTemplateError] =
    useDocumentData(
      liveNotesTemplateOid === "new"
        ? undefined
        : doc(
            db,
            GROUPS_COLLECTION,
            activeGroupOid,
            LIVE_NOTES_TEMPLATE_SUBCOLLECTION,
            liveNotesTemplateOid,
          ).withConverter(makeConverter<LiveNotesTemplate>()),
    )

  useEffect(() => {
    if (liveNotesTemplate) {
      setTemplateContent(liveNotesTemplate.content)
      setTemplateName(liveNotesTemplate.name)
    }
  }, [liveNotesTemplate])

  const saveTemplate = useCallback(async () => {
    if (!templateContent || !templateName) {
      handleError({
        error: new Error("template name or content missing"),
        prefix:
          "Missing name or content, try refreshing or recreating the template",
      })
      return
    }
    setSaving(true)
    try {
      const data = {
        name: templateName,
        content: templateContent,
        mimetype: "text/markdown" as const,
      }
      if (liveNotesTemplateOid === "new") {
        // Create
        const newOid = await createLiveNotesTemplate(
          authUser,
          activeGroupOid,
          data,
        )
        navigate(`/live-assistant/live-notes-templates/${newOid}`)
      } else {
        // Update
        await updateLiveNotesTemplate(authUser, activeGroupOid, {
          oid: liveNotesTemplateOid,
          ...data,
        })
      }

      handleSuccess("Template saved")
    } catch (error) {
      handleError({ error, prefix: "Couldn't save template" })
    } finally {
      setSaving(false)
    }
  }, [
    handleError,
    handleSuccess,
    templateContent,
    templateName,
    navigate,
    authUser,
    activeGroupOid,
    liveNotesTemplateOid,
  ])

  const deleteTemplate = useCallback(async () => {
    setDeleting(true)
    try {
      await deleteLiveNotesTemplate(activeGroupOid, liveNotesTemplateOid)
      navigate("/live-assistant/live-notes-templates")
    } catch (error) {
      handleError({ error, prefix: "Couldn't delete template" })
    } finally {
      setDeleting(false)
    }
  }, [handleError, activeGroupOid, navigate, liveNotesTemplateOid])

  if (liveNotesTemplateLoading) {
    return (
      <div className="flex gap-4 p-4">
        <Skeleton active loading />
      </div>
    )
  }
  if (!liveNotesTemplate && liveNotesTemplateOid !== "new") {
    return <div>Error: Template does not exist</div>
  }

  if (liveNotesTemplateError) {
    return <div>Error: {liveNotesTemplateError.message}</div>
  }

  const disabled = saving || deleting || liveNotesTemplateLoading

  return (
    <Form
      className="w-full max-w-[1000px] justify-stretch gap-2 p-4"
      layout="vertical"
      onFinish={saveTemplate}
    >
      <Form.Item
        label="Template Name"
        tooltip="Name of the template"
        rules={[{ required: true }]}
      >
        <Input
          disabled={disabled}
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        label="Template Content (markdown)"
        tooltip="Content of the template"
        rules={[{ required: true }]}
      >
        <MarkdownEditor
          className="rounded-lg border"
          readOnly={disabled}
          initialContent={liveNotesTemplate?.content}
          onChange={setTemplateContent}
        />
      </Form.Item>

      <div className="flex gap-2">
        <Button
          type="primary"
          htmlType="submit"
          disabled={disabled}
          loading={saving}
        >
          {saving ? "Saving" : "Save"}
        </Button>
        <Popconfirm
          title="Delete the template"
          description="Are you sure to delete this template?"
          onConfirm={deleteTemplate}
          okText="Yes"
          cancelText="No"
        >
          <Button type="default" danger disabled={disabled} loading={saving}>
            {deleting ? "Deleting" : "Delete"}
          </Button>
        </Popconfirm>
      </div>
    </Form>
  )
}

export default LiveNotesTemplateEditor
